<template>
    <ul class="sorting-options">
        <li v-for="(option, i) in section.options" :key="option.value" :class="{ hidden: !showOption(section, option, i) }">
            <label>
                <input
                    type="checkbox"
                    :name="section.key"
                    v-model="model"
                    :value="option.value"
                    :disabled="!isOptionEnabled(section, option)"
                />
                <i class="fa fa-fixed-width filterby-on-plp-checkbox" :class="optionCheckedClass(section, option)"></i>
                <span class="filterby-on-plp-checkbox">
                    {{ option.label }}
                    <template v-if="optionCount(section, option) !== false">
                        <span role="presentation" aria-hidden="true">({{ optionCount(section, option) }})</span>
                        <span class="sr-only">. There {{ optionCount(section, option) === 1 ? 'is' : 'are' }} {{ optionCount(section, option) }} {{ optionCount(section, option) === 1 ? 'product' : 'products' }} available for this filter.</span>
                    </template>
                </span>
            </label>
        </li>
    </ul>

    <button
        type="button"
        v-if="section.key !== 'price' && section.options.length > seeMoreLimit"
        @click="seeMoreToggle = !seeMoreToggle"
        class="seemore-text"
    >
        {{ !seeMoreToggle ? 'See More' : 'See Less' }}
    </button>
</template>

<script setup>
const model = defineModel();
const seeMoreToggle = defineModel('seeMoreToggle');

const props = defineProps({
    section: {},
    seeMoreLimit: Number,
    isOptionEnabled: Function,
    optionCount: Function,
    showOption: Function,
});

function optionCheckedClass(section, option) {
    return model.value?.includes(option.value)
        ? 'fa-check-square-o'
        : 'fa-square-o';
}
</script>
