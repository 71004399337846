<template>
    <div v-if="model">
        <div class="sorting-option-title sorting-option-title--postal-code">
            <div class="pull-left">Delivery Postal Code</div>
            <div class="clearfix"></div>
        </div>

        <div class="sorting-options sorting-options--postal-code">
            <a
                @click="clear"
                @keyup.enter="clear"
                title="Remove the Delivery Postal Code facet"
                class="clear-filter-link clear-filter-link-price"
            >
                    <span class="filter_option">
                        <span class="clear" aria-hidden="true" role="presentation">&times;</span>
                        <span aria-hidden="true" role="presentation">{{ model }}</span>
                        <span class="sr-only">Clear results for {{ model }} postal code.</span>
                    </span>
            </a>
        </div>
    </div>

    <div v-else>
        <div class="sorting-option-title">
            <div class="pull-left">Delivery Postal Code</div>
            <div class="clearfix"></div>
        </div>
        <form class="mobile-filter-postal-code" @submit.prevent="applyPostalCode">
            <input
                type="text"
                placeholder="Enter zip code"
                v-model.lazy.trim="value"
                @keydown="error = ''"
                :class="error ? 'error' : ''"
                :aria-errormessage="error ? 'zip-error-message' : ''"
            />

            <button
                type="submit"
                class="search-btn delivery-zip-filter-btn"
            >
                <span role="presentation" aria-hidden="true">Set</span>
                <span class="sr-only">
                    Filter for results within the entered postal code.
                </span>
            </button>

            <template v-if="error">
                <span id="zip-error-message" class="error-msg">
                    {{ error }}
                </span>
            </template>
        </form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const model = defineModel({ required: true,  });
const baseUrl = defineModel('baseUrl');

const value = ref('');
const isZipApplied = ref(!!model.value);
const error = ref('');
const baseUrlOriginal = baseUrl.value;

watch (model, () => isZipApplied.value = !!model.value);

function applyPostalCode() {
    const xhr = new XMLHttpRequest();
    const url = new URL(baseUrl.value);
    url.pathname = '/catalog/search/zipValidationAjax';
    url.searchParams.delete('zipcode');
    url.searchParams.set('zip', value.value);

    xhr.open('GET', url.toString(), true);

    xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
            if (xhr.responseText === 'error') {
                error.value = 'Please enter a valid US postal code.';
            } else {
                model.value = value.value;
                baseUrl.value = xhr.responseText;
            }
        } else {
            error.value = 'Connection error, please try again momentarily';
        }
    };

    xhr.onerror = function () {
        error.value = 'Connection error, please try again momentarily';
    };

    xhr.send();
}

function clear() {
    value.value = model.value;
    model.value = '';
    error.value = '';
    baseUrl.value = baseUrlOriginal.replace(/\?zipcode=.*/, '');
}
</script>
