<template>
    <ul class="sorting-options">
        <li v-for="option in options" :key="option.value">
            <label class="mobile-filters-radio-wrapper">
                <input
                    type="radio"
                    :name="name"
                    v-model="model"
                    class="hidden"
                    :value="option.value"
                />
                <span class="mobile-filters-radio"></span>
                <span class="mobile-filters-label">
                {{ option.label }}
            </span>
            </label>
        </li>
    </ul>
</template>

<script setup>
    const model = defineModel();

    const props = defineProps({
        options: {},
    });
</script>
