
<template>
    <div class="mobile-filters-toolbar" :class="toolbarShownClass">
        <div class="mobile-filters-container">
            <div class="header">
                <div class="title">Options</div>
                <button class="close-mobile-filters-container" @click="close">
                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.6297 13.5778C15.0688 14.0169 15.0688 14.7295 14.6297 15.1687C14.1905 15.6079 13.478 15.6079 13.0387 15.1687L7.49999 9.58869L1.92186 15.1668C1.48269 15.606 0.770145 15.606 0.330926 15.1668C-0.108293 14.7276 -0.108246 14.0151 0.330926 13.5759L5.91093 7.99963L0.329379 2.37932C-0.109793 1.94015 -0.109793 1.2276 0.329379 0.788381C0.768551 0.349162 1.4811 0.349209 1.92032 0.788381L7.49999 6.41057L13.0781 0.832443C13.5173 0.393271 14.2298 0.393271 14.6691 0.832443C15.1083 1.27162 15.1082 1.98416 14.6691 2.42338L9.08905 7.99963L14.6297 13.5778Z"
                            fill="black"
                        />
                    </svg>
                </button>
            </div>

            <div class="mobile-toolbar-overflow-wrapper">
                <div class="sidebar-mobile">
                    <template v-if="sortByOptions.length">
                        <MobileFilterSectionTitle
                            :section="{ label: 'Sort By' }"
                            :active="false"
                        />

                        <!-- Section Header (Radio Buttons: Sort By) -->
                        <MobileFilterSectionOptionsRadio
                            name="sort-by"
                            :options="sortByOptions"
                            v-model="activeSortBy"
                        />
                    </template>

                    <template v-if="hasDeliveryLocation">
                        <MobileFilterPostalCode
                            v-model="deliveryLocation"
                            v-model:baseUrl="categoryUrl"
                        />
                    </template>

                    <template v-if="hasDeliveryDate">
                        <MobileFilterSectionTitle
                            :section="{ label: 'Delivery Date' }"
                            :active="false"
                        />

                        <input
                            type="date"
                            :min="new Date().toISOString().split('T')[0]"
                            v-model="deliveryDate"
                        />
                    </template>

                    <template v-if="hasPickupOnly">
                        <MobileFilterSectionTitle
                            :section="{ label: 'Pickup' }"
                            :active="false"
                        />

                        <MobileFilterSectionOptionsCheckbox
                            v-model="activeFilters['pickup_only']"
                            v-model:seeMoreToggle="seeMoreToggle['pickup_only']"
                            :section="{ key: 'pickup_only', options: [{ label: 'Display Pickup Only Items', value: '1' }]}"
                            :seeMoreLimit="seeMoreLimit"
                            :isOptionEnabled="() => true"
                            :optionCount="() => false"
                            :showOption="() => true"
                        />
                    </template>

                    <!-- Section Options (Checkbox, attributes) -->
                    <template v-for="section in sections" :key="section.key">
                        <MobileFilterSectionTitle
                            :section="section"
                            :active="activeFilters[section.key].length > 0"
                            @clear="clearSection(section)"
                        />

                        <MobileFilterSectionOptionsCheckbox
                            v-model="activeFilters[section.key]"
                            v-model:seeMoreToggle="seeMoreToggle[section.key]"
                            :section="section"
                            :seeMoreLimit="seeMoreLimit"
                            :isOptionEnabled="isOptionEnabled"
                            :optionCount="optionCount"
                            :showOption="showOption"
                        />
                    </template>
                </div>
            </div>
        </div>
        <div class="mobile-toolbar-footer">
            <div class="footer-button-container">
                <a class="clear-all-filters" @click="clearAll">Clear All
                    <span class="applied-filters-count" v-if="activeFiltersCount">
                        <span role="presentation" aria-hidden="true">({{ activeFiltersCount }})</span>
                        <span class="sr-only">{{ activeFiltersCount }} filters</span>
                    </span>
                </a>
            </div>
            <div class="footer-button-container">
                <a class="apply-filters" @click="apply">Apply</a>
            </div>
        </div>
        <div class="mobile-toolbar-overlay" @click="shown = false"></div>
    </div>
</template>

<script>
import MobileFilterSectionTitle from './MobileFilterSectionTitle.vue';
import MobileFilterSectionOptionsRadio from './MobileFilterSectionOptionsRadio.vue';
import MobileFilterSectionOptionsCheckbox from './MobileFilterSectionOptionsCheckbox.vue';
import MobileFilterPostalCode from './MobileFilterPostalCode.vue';

// We're copying filter sections and options from the Desktop view to build up the Mobile view.
function fetchFiltersFromDesktopElement() {
    const activeFilters = {};
    const filterCounts = {};
    const sections = [];
    const sectionsMap = {};

    const options = document.querySelectorAll('#sidebar .sorting-options label');
    for (const option of options) {
        const filter = option.dataset.filter;

        if (!sectionsMap[filter]) {
            activeFilters[filter] = [];
            filterCounts[filter] = {};

            sectionsMap[filter] = {
                label: option.dataset.filtername,
                key: filter,
                options: [],
            };
            if (!['delivery_date', 'zipcode', 'pickup_only'].includes(filter)) {
                sections.push(sectionsMap[filter]);
            }
        }

        sectionsMap[filter].options.push({
            label: option.dataset.label,
            value: option.dataset.value,
        });

        filterCounts[filter][option.dataset.value] = Number(option.dataset.count);

        if (option.dataset.checked === "true") {
            activeFilters[filter].push(option.dataset.value);
        }
    }

    return { activeFilters, filterCounts, sections };
}

export default {
    components: {
        MobileFilterPostalCode,
        MobileFilterSectionOptionsCheckbox,
        MobileFilterSectionOptionsRadio,
        MobileFilterSectionTitle,
    },
    data: () => ({
        shown: false,
        seeMoreToggle: {},
        activeFilters: {},
        filterCounts: {},
        sections: {},
        ...JSON.parse(document.getElementById('mobile-filters-toolbar').dataset.serverState),
    }),
    computed: {
        activeFiltersCount() {
            return Object.keys(this.activeFilters).reduce((count, filter) => count + this.activeFilters[filter].length, 0) - (!!this.deliveryLocation);
        },
        toolbarShownClass() {
            return this.shown ? [] : ['hidden'];
        },
        deliveryDate: {
            get() {
                return this.activeFilters.delivery_date?.[0] ?? '';
            },
            set(value) {
                if (value) {
                    this.activeFilters.delivery_date = [value];
                } else {
                    this.activeFilters.delivery_date = [];
                }
            }
        },
        deliveryLocation: {
            get() {
                return this.activeFilters.zipcode?.[0] ?? '';
            },
            set(value) {
                if (value) {
                    this.activeFilters.zipcode = [value];
                } else {
                    this.activeFilters.zipcode = [];
                }
            },
        },
    },
    watch: {
        shown(newValue) {
            // handle external page scroll and ada icon when filter toolbar is toggled
            document.body.classList.toggle('stop-scrolling', newValue);
            this.toggleAccessibilityPopup(!newValue);
        },
        activeFilters: {
            handler(newValue, oldValue) {
                // Don't trigger a filter count when initialized.
                if (!Object.keys(oldValue).length) {
                    return;
                }

                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();
                fetch(this.getActiveStatePath(this.filterCountsUrl, false, true), {
                    signal: this.abortController.signal,
                })
                    .then((response) => response.json())
                    .then((json) => {
                        this.abortController = null;

                        this.filterCounts = json.data;
                    });
            },
            deep: true,
        },
    },
    mounted() {
        const { activeFilters, filterCounts, sections } = fetchFiltersFromDesktopElement();

        this.activeFilters = activeFilters;
        this.filterCounts = filterCounts;
        this.sections = sections;
        this.shown = true;

        const mobileFiltersButtons = document.querySelectorAll('.mobile-filters-btn');
        mobileFiltersButtons.forEach((button) => button.addEventListener('click', () => this.shown = true));
    },
    methods: {
        toggleAccessibilityPopup(visible) {
            const element = document.getElementById('header-accessibilityPopupBtn');
            if (element) {
                element.style.display = visible ? 'inline-block' : 'none';
            }
        },
        isOptionEnabled(section, option) {
            return this.activeFilters[section.key].includes(option.value)
                || this.optionCount(section, option);
        },
        optionCount(section, option) {
            return this.filterCounts[section.key] && this.filterCounts[section.key][option.value] || 0;
        },
        showOption(section, option, i) {
            if (this.activeFilters[section.key].includes(option.value)) {
                return true;
            }

            // Price as a Pseudo-radio button (allow unchecking the active selection)
            if (section.key === 'price' && this.activeFilters[section.key].length) {
                return false;
            }

            // See More / Less
            if (section.key !== 'price' && section.options.length > this.seeMoreLimit && i >= this.seeMoreLimit && !this.seeMoreToggle[section.key]) {
                return false;
            }

            return true;
        },
        getActiveStatePath(urlPrefix, addCategoryPath = true, includeLocation = false) {
            let url = new URL(urlPrefix);

            // The first filter category may mutate our active filter state
            const activeFilters = JSON.parse(JSON.stringify(this.activeFilters));

            // Try to use the first filter as the category (exclude price)
            if (addCategoryPath) {
                for (const key of Object.keys(activeFilters)) {
                    if (!activeFilters[key].length) {
                        continue;
                    }
                    if (!this.shortUrlAttributes.includes(key)) {
                        continue;
                    }

                    let options = activeFilters[key];
                    if (this.flowersUrlAttributes.includes(key)) {
                        url.pathname = url.pathname.replace(/\/$/, '');
                        url.pathname += `/${options.shift()}${this.shortUrlSuffix}/`;
                    } else if (key === 'flower') {
                        url.pathname = url.pathname.replace(/\/$/, '');
                        url.pathname += `/${options.shift()}/`;
                    } else if (key === 'flow_sympathy_type') {
                        url.pathname = url.pathname.replace(/\/$/, '');
                        url.pathname += `/${this.shortUrlSympathyPrefix}${options.shift()}/`;
                    } else if (key === 'flow_wedding_type') {
                        url.pathname = url.pathname.replace(/\/$/, '');
                        url.pathname += `/${this.shortUrlWeddingPrefix}${options.shift()}/`;
                    }

                    break;
                }
            }

            // Every other filter gets appended as a query string
            for (const key of Object.keys(activeFilters)) {
                if (!activeFilters[key].length) {
                    continue;
                }

                let value;
                if (key === 'price') {
                    value = activeFilters[key].join(' ').replace(',', '-');
                } else {
                    value = activeFilters[key].join(' ');
                }

                url.searchParams.set(key, value);
            }

            if (this.searchText.length > 0) {
                url.searchParams.set('q', this.searchText);
            }

            if (this.activeSortBy && this.activeSortBy !== this.defaultSortBy) {
                const [ order, dir ] = this.activeSortBy.split('=');
                url.searchParams.set('order', order);
                url.searchParams.set('dir', dir);
            }

            // Marketplace sets a `location = 'City, State'` value necessary to filter non-zip results
            if (includeLocation && this.location) {
                url.searchParams.set('location', this.location);
            }

            if (this.zip) {
                url.searchParams.set('loc', this.zip);
            }

            if (this.vendor_id) {
                url.searchParams.set('vendor_id', this.vendor_id);
            }

            return url.href;
        },
        apply() {
            window.location.href = this.getActiveStatePath(this.categoryUrl);
        },
        clearSection(section) {
            this.activeFilters[section.key].length = 0;
        },
        clearAll() {
            window.location.href = this.categoryUrl;
        },
        close() {
            this.shown = false;
        },
    },
};
</script>
