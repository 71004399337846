import { createApp } from 'vue';
import MobileFiltersModal from './MobileFiltersModal.vue';

function waitToLoadUntilClick() {
    const mobileFiltersButtons = document.querySelectorAll('.mobile-filters-btn');

    function mountApp() {

        mobileFiltersButtons.forEach((button) => button.removeEventListener('click', mountApp));

        createApp(MobileFiltersModal)
            .mount('#mobile-filters-toolbar');
    }

    mobileFiltersButtons.forEach((button) => button.addEventListener('click', mountApp));
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', waitToLoadUntilClick);
} else {
    waitToLoadUntilClick();
}
